import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BookOnlinePage = () => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div class="standard-page-container">
        <div className="inner-container">
          <h1>Online Booking Coming Soon...</h1>
        </div>
      </div>
    </Layout>
  )
}

export default BookOnlinePage
